import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import './assets/main.css'
// import './assets/main.css'
import './assets/dark.css'
import store from './store'
Vue.config.productionTip = false
import Swal from 'sweetalert2'
// import VueSocialSharing from 'vue-social-sharing'

// Vue.use(VueSocialSharing);
new Vue({
  router,
  store,
  data:{
    Toast:null,
    Swal,
    Brender:false,
  },
  mounted(){
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 6000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
  },
  render: h => h(App)
}).$mount('#app')
