<template>
  <div style="max-width:438px;">
    <h4 class="text-center">fortune.gold Presale Address</h4>
    <img src="../assets/qr.png" class="d-block mx-auto" style="max-width: 150px;" alt="" srcset="">
<div class="mt-2 mx-auto d-block d-md-none">
  <div class="shadow d-inline-block pt-1 px-1 rounded" style="max-width: 87%;width: 283px;">
    <p class="mb-0"
    style="
        white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    font-size: 102%;
    line-height: 17px;
    text-overflow: ellipsis;
    "
    :title="wallet"
    >{{ wallet.slice(0, 12) }}......{{ wallet.slice(-12) }} </p>
  </div>
  <div class="shadow d-inline-block pt-1 rounded px-1 ml-1">
    <!-- <span style="cursor: pointer; font-size: 27px;line-height: 27px;" @click="copy('0x46D5aaC901320d424306A6779c750F6f55F2976E')"> &#x274F; </span> -->
    <img src="../assets/copy.png" alt="" style="    width: 23px;
    cursor: pointer;
    margin-top: -11px;" srcset="" @click="copy('0x46D5aaC901320d424306A6779c750F6f55F2976E')">
  </div>
</div>
<div class="mt-2 mx-auto d-none d-md-block">
  <div class="shadow d-inline-block pt-1 px-1 rounded">
    <p class="mb-0"
    style="
        white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    font-size: 15px;
    line-height: 17px;
    text-overflow: ellipsis;
    "
    >{{ wallet }}</p>
  </div>
  <div class="shadow d-inline-block pt-1 rounded px-1 ml-1">
    <!-- <span style="cursor: pointer; font-size: 27px;line-height: 27px;" @click="copy('0x46D5aaC901320d424306A6779c750F6f55F2976E')"> &#x274F; </span> -->
    <img src="../assets/copy.png" alt="" style="    width: 23px;
    cursor: pointer;
    margin-top: -11px;" srcset="" @click="copy('0x46D5aaC901320d424306A6779c750F6f55F2976E')">
  </div>
</div>
        

        
  </div>
</template>

<script>
export default {
  data(){
    return {
      wallet:"0x46D5aaC901320d424306A6779c750F6f55F2976E"
    }
  },
methods:{
  
  copy(link)
  {
    navigator.clipboard.writeText(link)
      this.$root.Toast.fire({icon: "success",title:"Presale Address Copied"})
  },
},
async mounted(){
  // await this.$store.dispatch("pullUsers")
  // this.sortAll()
}
}
</script>

<style>

</style>