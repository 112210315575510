<template>
    <div id="fgadminr">
      <b-modal id="modal-txs" title="Verified Transactions"  size="xl">
    <a v-for="anc in selectedTxs" :key="anc" :href="anc" target="_blank" class="d-block">{{anc}}</a>
  </b-modal>
      <div class="row">
        <!-- <div class="col-12"> -->
        <!-- </div> -->
        <div class="col-12">
          <div class="main-content">
      <div class="">
        <div class="container-fluid">
          <!-- <div>
            <input type="radio" v-model="active" name="" id="" value="Active" class=""> Active
            <input type="radio" v-model="active" name="" id="" value="Archived" class=""> Archived
          </div> -->
          <div class="header-body">
            <div class="row">
              <div class="col-12">
                <div class="accordion" role="tablist">
                  
                <b-card no-body class="mb-1">

                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 variant="info">Approve Mining Rights</b-button>
                  </b-card-header>
                  <b-form-checkbox v-model="isCustom" name="check-button" switch>
                    Custom Power Rights Approved 
                  </b-form-checkbox>

                  <b-collapse id="accordion-1"  accordion="my-accordion" role="tabpanel">
                    <b-card-body v-if="isCustom">
                      <div class="row">
                      <div class="my-1 col-lg-12">
                        <label for=""> Wallet for mining rights to be approved </label>
                        <input type="text" name="" placeholder="Requestor Wallet" id="" v-model="rWallet" class="form-control">
                      </div>
                      <div class="my-1 col-lg-12">
                        <label for=""> Ref. ID (Optional, copy from table )</label>
                        <input type="text" name="" placeholder="Request Reference ID(Optional)" id="" v-model="rRequest" class="form-control">
                      </div>
                      <div class="my-1 col-lg-12">
                        <label for="">
                          <span v-if="powerType">Select MinerX amount (eg for $1000 select 10)</span>
                          <span v-else>Select ProspectorX amount (eg for $50 select 10)</span>
                           
                          <b-form-checkbox v-model="powerType" name="check-button" class="d-inlne-block" switch>
                    Switch to Prospector Power 
                  </b-form-checkbox>
                        </label>
                        <input type="number" name="" id="" class="form-control" placeholder="Provide X Value" v-model="mValue">
                      </div>
                      <div class="my-1 col-12">
                        <label for="">NFT IDS to be approved (Required)</label>
                        <input type="number" name="" v-model="MID" placeholder="Provide NFT ID" id="" class="form-control">
                      </div>
                      <div class="my-1 col-12" v-if="isCustom">
                        <button class="btn btn-secondary" @click="inviteTwitter" v-if="UWallet">Invite Twitter</button>
                        <button class="btn btn-secondary" @click="approveCustom" v-if="UWallet">Approve Custom Rights</button>
                        <button class="btn btn-secondary" @click="connectWallet" v-else>Connect Wallet</button>
                        <p class="text-danger" v-if="error.length"> {{ error }}</p>
                      </div>
                      <div class="my-1 col-12" v-else>
                        <button class="btn btn-secondary" @click="approveAll" v-if="UWallet">Approve Selected Rights</button>
                        <button class="btn btn-secondary" @click="connectWallet" v-else>Connect Wallet</button>
                        <p class="text-danger" v-if="error.length"> {{ error }}</p>
                      </div>
                    </div>
                    </b-card-body>
                    <b-card-body v-else>
                      <div class="row">
                      <div class="my-1 col-lg-12">
                        <label for=""> Wallet for mining rights to be approved </label>
                        <input type="text" name="" placeholder="Requestor Wallet" id="" v-model="rWallet" class="form-control">
                      </div>
                      <div class="my-1 col-lg-6">
                        <label for=""> Ref. ID (Optional, copy from table )</label>
                        <input type="text" name="" placeholder="Request Reference ID(Optional)" id="" v-model="rRequest" class="form-control">
                      </div>
                      <div class="my-1 col-lg-6">
                        <label for="">Select the class (Required)</label>
                        <select name="" id="" v-model="rClass" class="form-control">
                        <option value="Refiners">Refiners</option>
                        <option value="Miners">Miners</option>
                        <option value="Prospectors">Prospectors</option>
                        </select>
                      </div>
                      <div class="my-1 col-12">
                        <label for="">NFT IDS to be approved (Required)</label>
                        <b-form-tags input-id="tags-basic" placeholder="Input ID and press Enter" v-model="IDS"></b-form-tags>
                      </div>
                      <div class="my-1 col-12">
                        <button class="btn btn-secondary" @click="inviteTwitter" v-if="UWallet">Invite Twitter</button>
                        <button class="btn btn-secondary" @click="approveAll" v-if="UWallet">Approve Rights.</button>
                        <button class="btn btn-secondary" @click="connectWallet" v-else>Connect Wallet</button>
                        <p class="text-danger" v-if="error.length"> {{ error }}</p>
                      </div>
                    </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 variant="info">Mining Rights Requests</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2"  accordion="my-accordion-2" role="tabpanel">
                    <b-card-body class="">
                      <div class="input-group mb-3">
  <input type="text" class="form-control" placeholder="Recipient's Wallet" v-model="enSearchWallet" aria-label="Recipient's Wallet" aria-describedby="basic-addon2">
  <div class="input-group-append">
    <button class="btn btn-outline-secondary" type="button" @click="searchWallets" v-if="!enSearch">Search</button>
    <button class="btn btn-outline-secondary" type="button" @click="searchWalletsDisable" v-else>Clear</button>
  </div>
</div>
                      <table class="table">
                        <thead>
                          <tr>
                            <!-- <th> Ref. ID </th> -->
                            <th> For </th>
                            <th> From </th>
                            <th> Ver. Purchases </th>
                            <th> Req.USD </th>
                            <th> Sent </th>
                            <th> FORTUNE </th>
                            <th> IDS </th>
                            <th> Date </th>
                            <th> Actions </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="request in pendingNFTsFilteredPag" :key="request._id">
                          <!-- <td><b class="btn btn-link" @click="copyName(request._id, 'Reference ID Copied')">{{ request._id.slice(0, 5)+'.....'+request._id.slice(-5) }}</b></td> -->
                          <td>{{ request.for }}</td>
                          <!-- <td>{{ request.from}}</td> -->
                          <td><b class="btn btn-link" @click="copyName(request.from.toLowerCase(), 'From Wallet Copied')">{{ request.from.toLowerCase().slice(0, 8)+'.....'+request.from.toLowerCase().slice(-8) }}</b></td>
                          <td style="display: inline-block;text-align: left;width: 176px;">
                            <span v-if="verifiedGrouped[request.from] !== undefined">

                              <span v-if="verifiedGrouped[request.from].Miners !== undefined">M: {{ verifiedGrouped[request.from].Miners.totalQty }}, </span>
                      <span v-else>M:0,</span>
                      
                      <span v-if="verifiedGrouped[request.from].Prospectors !== undefined"> P:{{ verifiedGrouped[request.from].Prospectors.totalQty }}, </span>
                      <span v-else>P:0</span>
                      <span class="btn btn-link" @click="openTxModal(request)">
                        ($
                      {{
                        ((verifiedGrouped[request.from].Miners !== undefined ) ? (verifiedGrouped[request.from].Miners.totalQty * 100) : 0 )
                        +
                        ((verifiedGrouped[request.from].Prospectors !== undefined ) ? (verifiedGrouped[request.from].Prospectors.totalQty * 5) : 0 )
                      }})
                      </span>
                            </span>
                            <span v-else>
                              N/A
                            </span>
                          </td>
                          <td>
                            <span v-if="request.USD !== '0'">${{ request.USD }}</span>
                            <span v-else> 
                            <span v-if="request.for == 'Miners'">${{ ( 100 * Number(request.ids.length)) }}</span>
                            <span v-if="request.for == 'Prospectors'"> ${{ ( 5 * Number(request.ids.length)) }} </span>
                            </span>
                          </td>

                          <td v-if="dist[request.from.toLowerCase()] !== undefined">
                        <span class="text-success" v-if="dist[request.from.toLowerCase()].PIDS !== '' || dist[request.from.toLowerCase()].MIDS  !== '' "> 
                        {{(dist[request.from.toLowerCase()].PIDS !== '' ) ? "PIDS("+dist[request.from.toLowerCase()].PIDS.replace(/\r\n/g, '')+")" : ""  }}
                        {{(dist[request.from.toLowerCase()].MIDS !== '' ) ? "MIDS("+dist[request.from.toLowerCase()].MIDS.replace(/\r\n/g, '')+")" : ""  }}
                      </span>  
                      </td>
                          <td v-else>N/A</td>
                          <td v-if="nftUsers[request.from.toLowerCase()] !== undefined">
                        <span class="text-success" v-if="nftUsers[request.from.toLowerCase()].length"> <b class="pr-2">&#x2713;</b> {{nftUsers[request.from.toLowerCase()]}} </span>
                        <span class="text-success" v-else> N/A  </span>
                      </td>
                          <td v-else>N/A</td>
                          <td>{{ request.ids }}</td>
                          <td>{{ getShortestDate(new Date(request.createdAt))   }}</td>
                          <td> 
                            <button class="btn btn-sm my-1 btn-warning d-block" @click="populate(request)">Load</button> 
                            <!-- <button class="btn btn-sm my-1 btn-success d-block" @click="markAsApproved(request)">Mark As Approved</button>  -->
                            <button class="btn btn-sm my-1 btn-danger" @click="dlt(request)">Delete</button>
                           </td>
                          </tr>
                        </tbody>
                      </table>
                      <b-pagination
              v-model="currentPageM"
              :total-rows="pendingNFTsFiltered.length"
              :per-page="perPageM"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
              next
              </template>
              <template #next-text>
              Prev.
              </template>
            </b-pagination>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 variant="info">Verified Purchases Breakthrough by Wallet</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-3" visible accordion="my-accordion-3" role="tabpanel">
                    <!-- <button class="btn btn-primary float-right" @click="downloadCSV('withWallet')">Export CSV</button> -->
                    <b-card-body class="" >
                      <!-- <div v-if="showVP" > -->
                        <div class="input-group mb-3">
                      <input type="text" class="form-control" placeholder="Recipient's Wallet" v-model="pSearchWallet" aria-label="Recipient's Wallet" aria-describedby="basic-addon2">
                      <input type="text" class="form-control" placeholder="Recipient's Email" v-model="pSearchEmail" aria-label="Recipient's Email" aria-describedby="basic-addon2">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" @click="pSearchWallets" v-if="!pSearch">Search</button>
                        <button class="btn btn-outline-secondary" type="button" @click="pSearchWalletsDisable" v-else>Clear</button>
                      </div>
                    </div>
                    <!-- <div class="input-group mb-3"> -->
                      <button class="btn btn-success w-50" @click="downloadCSV(verifiedGroupedEMFilteredPag)">Export Current</button>
                      <!-- <div class="input-group-append"> -->
                        <button class="btn btn-success w-50" @click="downloadCSV(verifiedGroupedWallets)" >Export All</button>
                      <!-- </div> -->
                    <!-- </div> -->
                    <div style="overflow:auto;">
                      <table class="table">
                  <thead class="tthead">
                    <tr>
                      <td>Email</td>
                      <td>Wallet</td>
                      <td>Fortune.gold</td>
                      <td>Claimed</td>
                      <!-- <td>Refin</td> -->
                      <td>Min.</td>
                      <td>Pros.</td>
                      <td>Cus.</td>
                      <td>Sent</td>
                      <td>Pending</td>
                      <td>Mint/SelfMint</td>
                      <td>Ref/SelfRef</td>
                      <td>TXs</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in verifiedGroupedEMFilteredPag" :key="key">
                      <td>{{ key }}</td>
                      <td>{{ verifiedGroupedWallets[key].wallet }}</td>
                      <td> {{verifiedGroupedEMFilteredPag[key].data.fortune }} </td>
                      <td v-if="verifiedGroupedEMFilteredPag[key].data.claimed == null">PIDS(), MIDS()</td>
                      <td v-else>PIDS({{verifiedGroupedEMFilteredPag[key].data.claimed.PIDS}}) <br> MIDS({{verifiedGroupedEMFilteredPag[key].data.claimed.MIDS}})</td>

                      <td v-if="verifiedGroupedEM[key].Miners !== undefined"> {{ verifiedGroupedEM[key].Miners.totalQty }} </td>
                      <td v-else>0</td>
                      
                      <td v-if="verifiedGroupedEM[key].Prospectors !== undefined"> {{ verifiedGroupedEM[key].Prospectors.totalQty }} </td>
                      <td v-else>0</td>
                      <td>0</td>

                      <td>{{verifiedGroupedEMFiltered[key].data.sent}}</td>
                      <td>{{verifiedGroupedEMFiltered[key].data.total - verifiedGroupedEMFiltered[key].data.sent}}</td>
                      <td > {{verifiedGroupedEMFiltered[key].data.mint}} / {{verifiedGroupedEMFiltered[key].data.selfMint}}  </td>
                      <td > {{verifiedGroupedEMFiltered[key].data.ref}} / {{verifiedGroupedEMFiltered[key].data.selfRef}}  </td>
                      <td> <button class="btn btn-primary" @click="loadTXS(verifiedGroupedEMFiltered[key].data.txs)">View</button> </td>
                      <!-- <td > {{verifiedGroupedEMFiltered[key].data.ref}} / {{verifiedGroupedEMFiltered[key].data.selfRef}}  </td> -->
                      <!-- <td v-html="verifiedGroupedEMFiltered[key].data"> </td> -->
                      <!-- <td v-html="verifiedGroupedEMFiltered[key].data"> </td> -->
                    </tr>
                  </tbody>
                </table>
                
                    </div>
                
                
              <!-- </div> -->
                    </b-card-body>

                    <div class="pagination">
                  <b-pagination
              v-model="currentPage"
              :total-rows="Object.keys(verifiedGroupedEMFiltered).length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
              next
              </template>
              <template #next-text>
              Prev.
              </template>
            </b-pagination>
                </div>
                    </b-collapse>
                   </b-card>
                   <b-card no-body class="mb-1 d-none">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-4 variant="info">Verified Purchases Breakthrough by NO-Wallet</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-4" visible accordion="my-accordion-4" role="tabpanel">
                <div class="col-12" v-if="showVP">
                Refiners({{ (verifiedGroupedUndefined.Refiners !== undefined) ? verifiedGroupedUndefined.Refiners.totalQty : 0 }})

                <div v-if="verifiedGroupedUndefined.Refiners !== undefined">
                  <b-table small :fields="tFields" :items="verifiedGroupedUndefined.Refiners.items" responsive="sm">
                <template #cell(price)="data"> 
               $ {{data.item.priceUSD }} / {{ Number(data.item.priceCrypto).toFixed(2) }} {{ data.item.crypto }}  
                </template>  
                <template #cell(txHash)="data"> 
                <div style="margin-top: -15px;">
                    <span class="px-2" style="font-size:33px;" @click="copy(data.item.txHash)">&#x274F;</span>
                    <a :href="data.item.txHash" target="_blank" rel="noopener noreferrer"> <img src="../assets/resize (1).png" style="max-width:25px;margin-top:-12px;" alt="" srcset=""> </a>
                </div>
                </template> 
                <template #cell(purchaser)="data"> <div>{{ data.item.email }}</div></template> 
                <template #cell(fortune)="data"> <div>
                  <span class="text-success" v-if="nftUsersEmails[data.item.email] !== undefined"> <b class="pr-2">&#x2713;</b> {{nftUsersEmails[data.item.email]}} </span>
                        <span class="text-success" v-else> N/A  </span>
                </div></template> 
                <template #cell(refWallet)="data"> 
                <div>
                  {{ data.item.refWallet.slice(0, 6) }}......{{ data.item.refWallet.slice(-6) }}
                </div></template> 

                <template #cell(wallet)="data"> <div v-if="data.item.wallet"> {{ data.item.wallet.slice(0, 6) }}......{{ data.item.wallet.slice(-6) }}
                    <img src="../assets/copy.png" alt="" style="width: 23px;cursor: pointer;margin-top: -11px;" srcset="" @click="copyws(data.item.wallet,'Wallet')">
                  </div></template>
                </b-table>
                </div>
                Miners({{ (verifiedGroupedUndefined.Miners !== undefined) ? verifiedGroupedUndefined.Miners.totalQty : 0 }})
                
                <div v-if="verifiedGroupedUndefined.Miners !== undefined">
                  <b-table small :fields="tFields" :items="verifiedGroupedUndefined.Miners.items" responsive="sm">
                <template #cell(price)="data"> 
               $ {{data.item.priceUSD }} / {{ Number(data.item.priceCrypto).toFixed(2) }} {{ data.item.crypto }}  
                </template>  
                <template #cell(fortune)="data"> <div>
                  <span class="text-success" v-if="nftUsersEmails[data.item.email] !== undefined">  {{nftUsersEmails[data.item.email]}} </span>
                        <span class="text-success" v-else> N/A  </span>
                </div></template> 
                <template #cell(txHash)="data"> 
                <div style="margin-top: -15px;">
                    <span class="px-2" style="font-size:33px;" @click="copy(data.item.txHash)">&#x274F;</span>
                    <a :href="data.item.txHash" target="_blank" rel="noopener noreferrer"> <img src="../assets/resize (1).png" style="max-width:25px;margin-top:-12px;" alt="" srcset=""> </a>
                </div>
                </template> 
                <template #cell(purchaser)="data"> <div>{{ data.item.email }}  </div></template> 
                <template #cell(refWallet)="data"> 
                <div>
                  {{ data.item.refWallet.slice(0, 6) }}......{{ data.item.refWallet.slice(-6) }}
                </div></template> 
                <template #cell(wallet)="data"> <div v-if="data.item.wallet"> {{ data.item.wallet.slice(0, 6) }}......{{ data.item.wallet.slice(-6) }}
                    <img src="../assets/copy.png" alt="" style="width: 23px;cursor: pointer;margin-top: -11px;" srcset="" @click="copyws(data.item.wallet,'Wallet')">
                  </div></template>
                </b-table>
                </div>
                Prospectors({{ (verifiedGroupedUndefined.Prospectors !== undefined) ? verifiedGroupedUndefined.Prospectors.totalQty : 0 }})
                
                <div v-if="verifiedGroupedUndefined.Prospectors !== undefined">
                  <b-table small :fields="tFields" :items="verifiedGroupedUndefined.Prospectors.items" responsive="sm">
                <template #cell(price)="data"> 
               $ {{data.item.priceUSD }} / {{ Number(data.item.priceCrypto).toFixed(2) }} {{ data.item.crypto }}  
                </template>  
                <template #cell(fortune)="data"> <div>
                  <span class="text-success" v-if="nftUsersEmails[data.item.email] !== undefined">  {{nftUsersEmails[data.item.email]}} </span>
                        <span class="text-success" v-else> N/A  </span>
                </div></template> 
                <template #cell(txHash)="data"> 
                <div style="margin-top: -15px;">
                    <span class="px-2" style="font-size:33px;" @click="copy(data.item.txHash)">&#x274F;</span>
                    <a :href="data.item.txHash" target="_blank" rel="noopener noreferrer"> <img src="../assets/resize (1).png" style="max-width:25px;margin-top:-12px;" alt="" srcset=""> </a>
                </div>
                </template> 
                <template #cell(purchaser)="data"> <div>{{ data.item.email }}</div></template> 
                <template #cell(refWallet)="data"> 
                <div>{{ data.item.refWallet.slice(0, 6) }}......{{ data.item.refWallet.slice(-6) }}</div></template> 
                <template #cell(wallet)="data"> <div v-if="data.item.wallet"> {{ data.item.wallet.slice(0, 6) }}......{{ data.item.wallet.slice(-6) }}
                    <img src="../assets/copy.png" alt="" style="width: 23px;cursor: pointer;margin-top: -11px;" srcset="" @click="copyws(data.item.wallet,'Wallet')">
                  </div></template>
                </b-table>
                </div>
                
              </div>
                    </b-collapse>
                   </b-card> 
                </div>
                
              </div>
              <div class="col-12">
                <h3>  </h3>
              </div>
              
              <div class="col-12 d-none" v-if="showSTS">
                <h3> NFTs mining rights status in mining rights requests </h3>
                <table class="table">
                  <thead>
                    <tr>
                      <td>ID</td>
                      <td>Refiner</td>
                      <td>Miner</td>
                      <td>Prospector</td>
                      <td>Mineable</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in approvedAll" :key="key">
                      <td>{{ key }}</td>
                      <td> <img src="@/assets/cross.svg" alt="" srcset="" style="width: 30px;"> </td>
                      <td><img src="@/assets/cross.svg" alt="" srcset="" style="width: 30px;"></td>
                      <td><img src="@/assets/cross.svg" alt="" srcset="" style="width: 30px;"></td>
                      <td v-if="value"><img src="@/assets/tick.svg" alt="" srcset="" style="width: 30px;"></td>
                      <td v-else><img src="@/assets/cross.svg" alt="" srcset="" style="width: 30px;"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>
      <!-- Page content -->
    </div>
    
        </div>
        
      </div>
  
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'
import Web3 from 'web3';
  export default {
    name: "Dashboard",
    components:{
    },
    computed:{
      ...mapState(['pendingNFTs','userWallet','approved', 'dist','nftUsers','nftUsersEmails','verified']),
      pendingNFTsFiltered(){
        let data = [];
        if(this.enSearch){
          this.pendingNFTs.forEach(element => {
            if(element.from.toLowerCase() == this.enSearchWallet){
              data.push(element)
            }
          });
        }else{
          data = this.pendingNFTs;
        }
data = data.reverse()
        return data
      },
      pendingNFTsFilteredPag() {
        let len = (this.currentPageM * this.perPageM)
        const keys = this.pendingNFTsFiltered.length;

        // Calculate the start index based on the desired length
        // const startIndex = Math.max(keys.length - len, 0);

        // Slice the keys based on the start index and desired length
        // const slicedKeys = keys.slice(startIndex, startIndex + len);

        // Create a new object with only the sliced keys
        const limitedItems = [];
        for (let index = 0; index < keys; index++) {
          if(index >= (len - 10) && index < (len + 1)) limitedItems.push( this.pendingNFTsFiltered[index] )
        }

        return limitedItems;
        },
      verifiedGrouped(){
          const groupedData = {};
          this.$store.state.verified.forEach(item => {
            const { wallet, type, qty, ...rest } = item;

            if (!groupedData[wallet]) {
              groupedData[wallet] = {};
            }

            if (!groupedData[wallet][type]) {
              groupedData[wallet][type] = {
                items: [],
                totalQty: 0
              };
            }

            groupedData[wallet][type].items.push({ ...rest });
            groupedData[wallet][type].totalQty += Number(qty);
            });
            return groupedData;
            },
            verifiedGroupedEM(){
          const groupedData = {};
          this.$store.state.verified.forEach(item => {
            const { wallet,email,type, qty, ...rest } = item;

            if (!groupedData[email]) {
              groupedData[email] = {wallet};
            }

            if (!groupedData[email][type]) {
              groupedData[email][type] = {
                items: [],
                totalQty: 0
              };
            }

            groupedData[email][type].items.push({ ...rest });
            groupedData[email][type].totalQty += Number(qty);
            });
            const sortedKeys = Object.keys(groupedData).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));

            const sortedObject = {};
            sortedKeys.forEach(key => {
              sortedObject[key] = groupedData[key];
            });
            
            return sortedObject;
            },
            // <td>Email</td>
            //           <td>Wallet</td>
            //           <td>Fortune.gold</td>
            //           <td>Claimed</td>
            //           <!-- <td>Refin</td> -->
            //           <td>Min.</td>
            //           <td>Pros.</td>
            //           <td>Cus.</td>
            //           <td>Sent</td>
            //           <td>Pending</td>
            //           <td>Mint/SelfMint</td>
            //           <td>Ref/SelfRef</td>
            //           <td>TXs</td>
verifiedGroupedWallets(){
  let UND = {}
  for (const key in this.verifiedGroupedEM) {
    // console.log("the key is ", key)
    if(key !== "undefined"){
      UND[key] = this.verifiedGroupedEM[key]
      UND[key].wallet = (UND[key].wallet == undefined) ? "NOWALLET" : UND[key].wallet
      let wallet = UND[key].wallet;
        let mint = 0,selfMint = 0,selfRef = 0,ref = 0,txs = [], fortune = null, claimed = null, sent = null,total = 0, pending = null;
        total = ((this.verifiedGroupedEM[key].Miners !== undefined) ? this.verifiedGroupedEM[key].Miners.totalQty : 0)
                            +
                            ((this.verifiedGroupedEM[key].Prospectors !== undefined) ? this.verifiedGroupedEM[key].Prospectors.totalQty : 0)
        if(this.dist[this.verifiedGroupedEM[key].wallet.toLowerCase() ] !== undefined){
          claimed = {PIDS:"",MIDS:""};
          if (this.dist[this.verifiedGroupedEM[key].wallet.toLowerCase()].PIDS  !== null) {
            claimed.PIDS = this.dist[this.verifiedGroupedEM[key].wallet.toLowerCase()].PIDS.replace(/\r\n/g, '')
          }
           // csv += (this.dist[key].PIDS !== null ) ? "PIDS("+this.dist[key].PIDS.replace(/\r\n/g, '')+")-" : "PIDS()-"
        // csv += (this.dist[key].MIDS !== null ) ? "MIDS("+this.dist[key].MIDS.replace(/\r\n/g, '')+")-" : "MIDS()-"
          if (this.dist[this.verifiedGroupedEM[key].wallet.toLowerCase()].MIDS  !== null) {
            claimed.MIDS = this.dist[this.verifiedGroupedEM[key].wallet.toLowerCase()].MIDS.replace(/\r\n/g, '')
          }
          sent = ( (this.dist[this.verifiedGroupedEM[key].wallet.toLowerCase()].PIDS !== null )  ? 
                          (this.dist[this.verifiedGroupedEM[key].wallet.toLowerCase()].PIDS.match(/#/g) || []).length : "" ) 
                          + 
                          ( (this.dist[this.verifiedGroupedEM[key].wallet.toLowerCase()].MIDS !== null )  ?

                          (this.dist[this.verifiedGroupedEM[key].wallet.toLowerCase()].MIDS.match(/#/g) || []).length : "" ) 
          pending = (total - sent)                


        }

        fortune = (this.nftUsersEmails[key] !== undefined && this.nftUsersEmails[key].length) ? this.nftUsersEmails[key] : null;
        if(UND[key].Prospectors !== undefined){
          if(UND[key].Prospectors !== undefined && UND[key].Prospectors.items.length)
          {
            let qt = Number(UND[key].Prospectors.totalQty)
            UND[key].Prospectors.items.forEach(element => {
              qt  = Number(qt)
              if(element.isSelfmint) selfMint += (Number(element.priceUSD) / 5)
              else mint += (Number(element.priceUSD) / 5)
              if(element.refWallet == wallet) selfRef += (Number(element.priceUSD) / 5)
              else ref += (Number(element.priceUSD) / 5)
              txs.push(element.txHash)

            });
          }
          }
        if(UND[key].Miners !== undefined){
          if(UND[key].Miners !== undefined && UND[key].Miners.items.length)
          {
            let qt = Number(UND[key].Miners.totalQty)
            UND[key].Miners.items.forEach(element => {
              qt  = Number(qt)
              if(element.isSelfmint) selfMint += qt
              else mint += qt
              if(element.refWallet == wallet) selfRef += qt
              else ref += qt
              txs.push(element.txHash)

            });
          }
        }
    // <span class="d-inline-block" style="width:100px">Ref/SelfRef</span>

                // MINT/SelfMint -- Ref/SelfRef -- TXs
        let res = {mint, selfMint, ref, selfRef, txs, fortune,claimed, sent, total, pending}
      UND[key].data = res
        
    }
  } 
  return UND
},
verifiedGroupedEMFiltered(){
        let data = {};
        if(this.pSearch){
          for (const key in this.verifiedGroupedWallets) {
            if(this.pSearchEmail.length && this.pSearchWallet.length){
              if(
              key.toLocaleLowerCase().includes(this.pSearchEmail.toLocaleLowerCase() ) 
              || 
              this.verifiedGroupedWallets[key].wallet.toLocaleLowerCase().includes(this.pSearchWallet.toLocaleLowerCase() ) 
              ) {
              data[key] = this.verifiedGroupedWallets[key]
            }
            }else{
              if(this.pSearchEmail.length){
                if(
              key.toLocaleLowerCase().includes(this.pSearchEmail.toLocaleLowerCase() ) 
              ) {
              data[key] = this.verifiedGroupedWallets[key]
            }
              }
              if(this.pSearchWallet.length){
                if( 
              this.verifiedGroupedWallets[key].wallet.toLocaleLowerCase().includes(this.pSearchWallet.toLocaleLowerCase() ) 
              ) {
              data[key] = this.verifiedGroupedWallets[key]
            } 
              }
            }
            
          }
          
        }else{
          data = this.verifiedGroupedWallets;
        }
        return data
      },
      verifiedGroupedEMFilteredPag() {
        let len = (this.currentPage * this.perPage)
        const keys = Object.keys(this.verifiedGroupedEMFiltered);

        // Calculate the start index based on the desired length
        // const startIndex = Math.max(keys.length - len, 0);

        // Slice the keys based on the start index and desired length
        // const slicedKeys = keys.slice(startIndex, startIndex + len);

        // Create a new object with only the sliced keys
        const limitedItems = {};
        keys.forEach((key, index) => {
          if(index >= (len - 10) && index < (len + 1))
          limitedItems[key] = this.verifiedGroupedEMFiltered[key];
        });

        return limitedItems;
        },

verifiedGroupedUndefined(){
  let UND = {}
  for (const key in this.verifiedGrouped) {
    // console.log("the key is ", key)
    if(key == "undefined"){
      UND = this.verifiedGrouped[key]
    }
  } 
  return UND
},
      approvedAll(){
        return this.$store.state.approved
      },
    },
    data(){
      return {
        enSearchWallet:"",
        pSearchWallet:"",
        pSearchEmail:"",
        currentPage:1,
        currentPageM:1,
        perPage:10,
        perPageM:10,
        enSearch:false,
        pSearch:false,
        active:"Active",
        showSTS:false,showVP:true,
        UWallet:null,
        selectedTxs:[],
        IDS:[],
        rRequest:"",
        isCustom:false,
        powerType:true,
        MID:"",
        mValue:0,
        error:"",
        rClass:"",
        rWallet:"",
        tFields:[
           {key: 'purchaser',label: 'Purchaser',sortable: false},
           {key: 'fortune',label: 'FORTUNE.GOLD',sortable: false},
        //    {key: 'qty',label: 'Quantity',sortable: false},
           {key: 'price',label: 'Price/Value',sortable: false},
           {key: 'txHash',label: 'Tx. Link',sortable: false},
           {key: 'isSelfmint',label: 'Self Mint',sortable: false},
           {key: 'refWallet',label: 'refWallet',sortable: false},
        //    {key: 'action',label: 'Actions',sortable: false},
               ],
      }
    },
    methods:{
      async inviteTwitter(){
        let ID = (this.isCustom) ? this.MID : this.IDS[0]
        let url = {};
        url = await this.$store.dispatch("getNFTArt", ID)
        let item = {};
        item.data = url;
        item.id = ID;
        item.status = "Created";
        item.wallet = this.rWallet;
        let dec = await this.$store.dispatch("addInvite", item);
        if(dec.status){
          alert("Invite Sent")
        }else{
          alert("error in sending Invite")
        }
        //get NFT ID
        //get wallet
        //get nft art image
        //save it to database
      },
      getElementsInRanges(array, startIndex, rangeSize) {
  return array.slice(startIndex, startIndex + rangeSize);
},
      populate(request){
        console.log(request)
        const startIndex = 100;
const rangeSize = 20;
        this.IDS = this.getElementsInRanges(request.ids, startIndex, rangeSize);
        this.rWallet = request.from
        this.rClass = request.for
        var collapseElement = document.getElementById('accordion-1');
        if (collapseElement) {
          collapseElement.classList.add('show');
          collapseElement.style = {display:"block;"};
        }
      },
      openTxModal(request){
        let r = request.from.toLocaleLowerCase()
        for (const key in  this.verifiedGroupedWallets) {
          if(this.verifiedGroupedWallets[key].wallet.toLocaleLowerCase() == r ){
            console.log("matched", this.verifiedGroupedWallets[key])
            this.loadTXS(this.verifiedGroupedWallets[key].data.txs )      
          }
        }
      },
      loadTXS(txs){
        this.selectedTxs = txs
        this.$bvModal.show("modal-txs")
      },
      
      searchWallets(){
        this.enSearch = true
      },
      searchWalletsDisable(){
        this.enSearchWallet = ""
        this.enSearch = false;
      },
      pSearchWallets(){
        this.pSearch = true
      },
      pSearchWalletsDisable(){
        this.pSearchWallet = ""
        this.pSearchEmail = ""
        this.pSearch = false;
      },
      getShortestDate(date) {
      const options = { month: 'short', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    },
      downloadCSV(records){
        // console.log("type is ", type)
    let csv = 'Email,Wallet,Fortune.gold,Miners,Prospectors,Custom,value,Sent,Pending,Sent IDS,Mint,Self Mint,Ref, Self Ref, TXs\n';
    for (const key in records) {
      csv += key;
      csv += ',';

      csv += records[key].wallet;
      csv += ',';

      csv += (records[key].data.fortune == null) ? "NO" : records[key].data.fortune;
      csv += ',';

      if (records[key].Prospectors) {
      csv += records[key].Prospectors.totalQty 
      csv += ',';
      }else{
      csv += ',';
      }
      if (records[key].Miners) {
      csv += records[key].Miners.totalQty
      csv += ',';
      }else{
      csv += ',';
      }
      csv+= ','
      let value = 0
      if (records[key].Prospectors) {
      value += (records[key].Prospectors.totalQty * 5)
      }
      if (records[key].Miners) {
      value += (records[key].Miners.totalQty * 100)
      }
      csv += value
      csv += ","

      csv += records[key].data.sent
      csv += ","

      csv += (records[key].data.total - records[key].data.sent)
      csv += ","
      // csv += (this.dist[key].PIDS !== null ) ? "PIDS("+this.dist[key].PIDS.replace(/\r\n/g, '')+")-" : "PIDS()-"
        // csv += (this.dist[key].MIDS !== null ) ? "MIDS("+this.dist[key].MIDS.replace(/\r\n/g, '')+")-" : "MIDS()-"
      csv += (records[key].data.claimed == null) ? "PIDS()-MIDS()" : `PIDS(${records[key].data.claimed.PIDS.replace(/\n/g, '')})-MIDS(${records[key].data.claimed.MIDS.replace(/\n/g, '')})`
      csv += ","

      csv += records[key].data.mint
      csv += ","

      csv += records[key].data.selfMint
      csv += ","

      csv += records[key].data.ref
      csv += ","

      csv += records[key].data.selfRef
      csv += ","

      csv += [...records[key].data.txs]
      csv += ","

      csv += "\n";
    }
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    anchor.target = '_blank';
    anchor.download = 'NFTAuditData.csv';
    anchor.click();
      },
      copyws(link, title)
  {
    navigator.clipboard.writeText(link)
      this.$root.Toast.fire({icon: "success",title:title+" Address Copied"})
  },
async connectWallet() {
    // const web3 = new Web3(window.ethereum);

  try {
    // Request account access
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    this.UWallet = accounts[0]

  } catch (error) {
    console.error('Error connecting to wallet:', error);
    // return null;
  }
},

async checkChainAndNetwork() {
    const web3 = new Web3(window.ethereum);
    let expectedChainId = "0xa573"

  try {
    const chainId = await web3.eth.getChainId();

    if (chainId !== expectedChainId) {
      // Show a prompt to change the chain
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: expectedChainId }], // Change to the desired chain ID
      }).then(async () => {
        this.connectWallet()
      })
    //   console.log("isSwitched", isSwitched)

    //   if (isSwitched) {
    //     console.log('Switched to the correct chain');
    //     this.connectWallet()
    //   } else {
    //     console.log('User rejected chain switch');
    //   }
    }else{
        this.connectWallet()
    }

  } catch (error) {
    console.error('Error checking chain and network:', error);
  }
},

        connect(){
this.checkChainAndNetwork()
        },
      async dlt(request){
        let dec = confirm("Are you sure you want to delete ? this action can not be undo") 
        if(dec){
          this.$store.dispatch("deleteRequests",{id: request._id})
        }
      },
      async approveCustom(){
        let cls = 0;
        this.error = ''
      // classes:["Refiners",'Miners','Prospectors'],
  if(this.rClass == "Refiners") cls = 1
  if(this.rClass == "Miners") cls = 2
  if(this.rClass == "Prospectors") cls = 3
  const web3 = new Web3(window.ethereum);
  let validWallet = web3.utils.isAddress(this.rWallet)
  if(this.mValue > 0 && this.MID.length > 0 && validWallet){
    let dec = await this.$store.dispatch("ApproveCustom", {cls:(this.powerType) ? 2 : 3, x: this.mValue,token: this.MID, customer: this.rWallet, wallet: this.UWallet, web3, request: (this.rRequest) ?  this.rRequest: "555555", for: "Miners"})
    console.log(dec)
    if(dec.status)
    {
      alert("Right Approved for IDS: ", this.IDS)
      // this.$store.dispatch("getRequestsAll")
      // this.IDS = [];
      // this.rClass = null;
      // this.rWallet = ""
      // this.MID = ""
      // this.mValue = 0
      // this.rRequest = ""
      this.error = ""
      // await this.$store.dispatch("updateRequest", {id:request._id, status:"Archived"})
      // this.$store.dispatch("getRequestsAll")
    }
    }else{
      if(cls < 1) this.error = "Need a Valid Class"
      if(this.IDS.length < 1) this.error = "Need at least 1 ID"
      if(!validWallet) this.error = "Need a valid Requestor Wallet"
    }
      },
      async approveAll(){
        console.log("running approveAll")
        let cls = 0;
        this.error = ''
      // classes:["Refiners",'Miners','Prospectors'],
  if(this.rClass == "Refiners") cls = 1
  if(this.rClass == "Miners") cls = 2
  if(this.rClass == "Prospectors") cls = 3
  const web3 = new Web3(window.ethereum);
  let validWallet = web3.utils.isAddress(this.rWallet)
  if(cls > 0 && this.IDS.length > 0 && validWallet){
    console.log("running approveAll, sending tx")

    let dec = await this.$store.dispatch("ApproveBatchAll", {ids: this.IDS, customer: this.rWallet, cls, wallet: this.UWallet, web3, request: this.rRequest, for: this.rClass})
    console.log("status of Approve All", dec)
    if(dec.status)
    {
      alert("Right Approved for IDS: ", this.IDS)
      // this.$store.dispatch("getRequestsAll")
      this.IDS = [];
      this.rClass = null;
      this.rWallet = ""
      this.rRequest = ""
      this.error = ""
      // await this.$store.dispatch("updateRequest", {id:request._id, status:"Archived"})
      // this.$store.dispatch("getRequestsAll")
    }
    }else{
      if(cls < 1) this.error = "Need a Valid Class"
      if(this.IDS.length < 1) this.error = "Need at least 1 ID"
      if(!validWallet) this.error = "Need a valid Requestor Wallet"
    }
      },
      async doApprove(request){
        let cls = 0;
      // classes:["Refiners",'Miners','Prospectors'],
  if(request.for == "Refiners") cls = 1
  if(request.for == "Miners") cls = 2
  if(request.for == "Prospectors") cls = 3
  if(cls > 0){
    const web3 = new Web3(window.ethereum);
    let dec = await this.$store.dispatch("ApproveBatchAll", {ids: request.ids, customer: request.from, cls, wallet: this.UWallet, web3})
    if(dec.status)
    {
      await this.$store.dispatch("updateRequest", {id:request._id, status:"Completed"})
      this.$store.dispatch("getRequestsAll")
    }
  }
        
      },
      async approve(request){
        let dec = confirm("Are you sure you want to approve ? this action can not be undo") 
        if(dec){
          this.doApprove(request)
        }
      },
      copyName(link, message){
        navigator.clipboard.writeText(link)
        alert(message)
        // this.$root.Toast.fire({icon: "success",title:"Wallet Copied"})
      },
      async markAsApproved(request){
        await this.$store.dispatch("updateRequest", {id:request._id, status:"Completed"})
      this.$store.dispatch("getRequestsAll")
      },
    },
    mounted(){
      this.$store.state.pendingNFTs = []
      // this.$store.state.userWallet = null
      this.$store.dispatch("getRequestsAll")
      setInterval(() => {
        this.showSTS = false;
        this.showSTS = true;

      }, 5000);
    }
  }
  </script>
  <style scoped>
  
  
  h2,
  h5,
  .h2,
  .h5 {
    font-family: inherit;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: .5rem;
    color: #32325d;
  }
  
  h5,
  .h5 {
    font-size: .8125rem;
  }
  
  @media (min-width: 992px) {
    
    .col-lg-6 {
      max-width: 50%;
      flex: 0 0 50%;
    }
  }
  
  @media (min-width: 1200px) {
    
    .col-xl-3 {
      max-width: 25%;
      flex: 0 0 25%;
    }
    
    .col-xl-6 {
      max-width: 50%;
      flex: 0 0 50%;
    }
  }
  
  
  .bg-danger {
    background-color: #f5365c !important;
  }
  
  
  
  @media (min-width: 1200px) {
    
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
  }
  
  
  .pt-5 {
    padding-top: 3rem !important;
  }
  
  .pb-8 {
    padding-bottom: 8rem !important;
  }
  
  @media (min-width: 768px) {
    
    .pt-md-8 {
      padding-top: 8rem !important;
    }
  }
  
  @media (min-width: 1200px) {
    
    .mb-xl-0 {
      margin-bottom: 0 !important;
    }
  }
  
  
  
  
  .font-weight-bold {
    font-weight: 600 !important;
  }
  
  
  a.text-success:hover,
  a.text-success:focus {
    color: #24a46d !important;
  }
  
  .text-warning {
    color: #fb6340 !important;
  }
  
  a.text-warning:hover,
  a.text-warning:focus {
    color: #fa3a0e !important;
  }
  
  .text-danger {
    color: #f5365c !important;
  }
  
  a.text-danger:hover,
  a.text-danger:focus {
    color: #ec0c38 !important;
  }
  
  .text-white {
    color: #fff !important;
  }
  
  a.text-white:hover,
  a.text-white:focus {
    color: #e6e6e6 !important;
  }
  
  .text-muted {
    color: #8898aa !important;
  }
  
  @media print {
    *,
    *::before,
    *::after {
      box-shadow: none !important;
      text-shadow: none !important;
    }
    
    a:not(.btn) {
      text-decoration: underline;
    }
    
    p,
    h2 {
      orphans: 3;
      widows: 3;
    }
    
    h2 {
      page-break-after: avoid;
    }
    
    
    body {
      min-width: 992px !important;
    }
  }
  
  figcaption,
  main {
    display: block;
  }
  
  main {
    overflow: hidden;
  }
  
  .bg-yellow {
    background-color: #ffd600 !important;
  }
  
  
  
  
  
  
  .icon {
    width: 3rem;
    height: 3rem;
  }
  
  .icon i {
    font-size: 2.25rem;
  }
  
  .icon-shape {
    display: inline-flex;
    padding: 12px;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }
  
  
  .card-body{
    padding: 1px 1px !important;
  }
  thead th, .tthead {
      position: sticky;
      top: 0;
      background-color: #f2f2f2;
    }
    .table-container {
      max-height: 555px;
      overflow-y: auto;
      border: 1px solid #ccc;
    }
    
  #fgadminr .row, 
  #fgadminr .container-fluid, 
  #fgadminr .col-12, 
  #fgadminr .col-sm-12, 
  #fgadminr .col{
    margin: 1px !important;
    padding: 1px !important;
  }
  </style>