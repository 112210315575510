<template>
  <div>
    <!-- <div id="navbarx" class="pgoldx d-none d-lg-block" style="padding-left:4%;">
    <div class="logo d-inline-block float-left ">
      <img src="../assets/images/goldx.png" v-if="$store.state.dark" class="link" @click="$router.push({path:'/'})" style="width:150px;" alt="">
        <img src="../assets/gxlogo.png" class="link" v-else @click="$router.push({path:'/'})" style="width:110px;" alt="">
    </div>
    <div class="d-inline-block float-right poaition-relative pt-3 px-3">
        <span class="navx-item mx-2"> Register Now </span>
        <a class="" ><span class="navx-item " v-b-modal.modal-xl> Mining Rights </span></a>
        <span class="navx-item mx-2"> Features & Security </span>
        <span class="navx-item mx-2"> Whitepapers </span>
        <span class="navx-item mx-2" @click="connectWallet" style="border: 2px solid #b99653;
    color: #b99653;
    padding: 5px 11px;
    border-radius: 5px;"> {{(connected) ? formattedUserWallet : "Connect Wallet"}} </span>
     

    </div>
  </div> -->
  <div>
    <nav class="navbar mx-0 navbar-expand-xxl px-md-5 navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <div class="logo d-md-none d-inline-block float-left">
        <img src="../assets/images/goldx.png" v-if="$store.state.dark" style="width:100px;" alt="">
        <img src="../assets/gxlogo.png" v-else style="width:102px;" alt="">
    </div>
    <div class="logo d-none d-md-inline-block float-left">
        <img src="../assets/images/goldx.png" v-if="$store.state.dark" style="width:100px;" alt="">
        <img src="../assets/gxlogo.png" v-else style="width:150px;" alt="">
    </div>

  </a>
  <button class="cgb st-shadow-green d-md-none"
  style=" position: absolute;
    right: 75px;
    padding: 7px 1px;
    border: none;
    width: 159px;
    text-align: center;
    background: rgb(255, 255, 255);
    color: black;
    cursor: pointer;
    border-radius: 12px;
    font-size: 94%;
"
  >
  <span style="display: inline-block;
    width: 80%;
    text-align: left;"  @click="connectWallet" > {{(connected) ? formattedUserWallet : "Connect Wallet"}} </span>
       <img src="../assets/images/mm.png" style="width: 14%;
    position: absolute;
    right: 5px;" alt="" srcset="">
  </button>
  <button class="cgb green-button st-shadow-green d-none d-md-block"
  style="    position: absolute;
    right: 115px;
    padding:6px 7px;
    border: none;
    width: 246px;
"
  >
  <span  @click="connectWallet" > {{(connected) ? formattedUserWallet : "Connect Wallet"}} </span>
       <img src="../assets/images/mm.png" style="width: 12%;float: right;" alt="" srcset="">
  </button>
  <button class="navbar-toggler" style="right:0px !important;" type="button" 
  data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <!-- <li class="nav-item active">
        <a class="nav-link" href="#"><span class="navx-item "> REGISTER NOW </span></a>
      </li> -->
      <li class="nav-item">
    <a class="nav-link" href="/registration">REGISTER NOW</a>
  </li>
      <li class="nav-item active">
        <a class="nav-link" ><span class="navx-item " v-b-modal.modal-xl> MINING RIGHTS </span></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" href="#"><span class="navx-item "> FEATURES & SECURITY </span></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" href="#"><span class="navx-item "> WHITEPAPERS </span></a>
      </li>
      <li class="nav-item">
    <button class="btn btn-gold" style="font-size:12px;" v-b-modal.modal-15>GET REFERRAL LINK</button> 
    <b-modal
    ok-only
    :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
      ok-variant='gold'
    id="modal-15" title="GET REFERRAL LINK">
    <label for="">Please provide your registered Email address</label>
    <input type="email" name="" id="" class="form-control" v-model="refEmail">
    <button class="btn btn-primary my-2 form-control" :disabled="refEmail.length < 1" @click="getReflink">Submit</button>
    <p class="text-danger" v-if="refLinkError.length">{{ refLinkError }}</p>
    <p class="text-success btn-link" style="word-break:break-all;cursor: pointer;" @click="copyLink" v-if="refLink.length" > {{ refLink }}</p>

    <!-- <p class="my-4">Hello from modal!</p> -->
  </b-modal>
  </li>
      <!-- <li class="nav-item active">
        <a class="nav-link" href="#"><span class="navx-item "> 
         
        </span>
      </a>
      </li> -->
      <!-- <span class="navx-item mx-2"> REGISTER NOW </span> -->
        
        
        
        
    </ul>
  </div>
</nav>
  </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
export default {
    data(){
        return {
            connected:false,
            userWallet:null,
            web3:null,
            provider:null,
            networkID:'0xa573',
            refLinkError:"",
        refLink:"",
        refEmail:"",
        headerBgVariant: 'light',
        headerTextVariant: 'dark',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        }
    },
    watch:{
      canConnectWallet (value) {
        // console.log("value is", value)
        if(value){
          this.connectWallet()
        }
      }
    },
    computed:{
      ...mapState(['canConnectWallet']),
      formattedUserWallet(){
        if(this.userWallet){
          return this.userWallet.substr(0, 6)+'....'+this.userWallet.substr(this.userWallet.length-4, 4);
        }else{
          return 'CONNECT WALLET'
        }
    },
    },
    mounted(){
      // console.log(this.$store.state.networkID)
      setTimeout(() => {
      }, 3000);

    },
    methods:{
      copyLink(){
        navigator.clipboard.writeText(this.refLink)
        this.$root.Toast.fire({icon: "success",title:"Referral Link Copied"})
      },
      async getReflink(){
        this.refLink = '';
        this.refLinkError = '';
        let link = await this.$store.dispatch("getRefLink", {email: this.refEmail})
        console.log(link)
        if (link.status) {
            this.refLink = link.refLink
        }else{
            this.refLinkError = link.error
        }
    },
      async connectWallet(){
        if(!this.connected){
// this.$bvModal.hide('modal-wallet')
console.log("running connect function")
          let dec = await this.setProvider(); 
          let accounts =  await this.web3.eth.getAccounts()
        if (accounts) {
        this.userWallet = accounts[0];
        this.$store.dispatch("getInvites", this.userWallet)
        this.$store.state.userWallet = accounts[0];

      }
          if (dec) {
            this.connected = true
                this.$store.dispatch("loadContracts", {provider: this.provider, wallet: this.userWallet})
                this.$store.dispatch("loadActivities", {})
                await this.$store.dispatch("loadNFTs",{wallet: this.userWallet, provider: this.provider})
          }
            
        
      
        }
      
     },
      async setProvider() {
      // const provider = new Web3(new Web3.providers.HttpProvider('https://data-seed-prebsc-1-s1.binance.org:8545'))
      const provider = await detectEthereumProvider();

        this.provider = null;
        this.userWallet = null;
        this.$store.state.userWallet = null;
      if (provider) {
        this.provider = provider;
        this.$store.state.provider = provider;
        this.web3 = new Web3(provider);
        this.provider.enable();

        console.log('provider', this.provider);
        const chainId = await provider.request({
            method: 'eth_chainId'
          })
        await this.provider.enable();

        console.log('chainID', chainId);
        window.ethereum.on('chainChanged', () => {
          // this.connectWallet()
          window.location.reload();
        })
        window.ethereum.on('accountsChanged', () => {
          window.location.reload();
          // this.connectWallet()
        })
        
        let ev = 1
        if (ev < 0) {
        // if (chainId !== this.networkID) {
          alert("Please Change to GOLDX MAINNET Network")
            // this.$root.Toast.fire({icon: "error",title:"Please Change to"+ this.NETWORK +"Test Network"})
        console.log(this.provider)
          try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: this.networkID }], // chainId must be in hexadecimal numbers
            // params: [{ chainId: this.networkID }], // chainId must be in hexadecimal numbers
          });
          // return false;  
          } catch (error) {
            console.log("error in switching chain", error)
            console.log("adding chain to metamask")
            window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
            chainId: this.networkID,
            chainName: 'GOLDX Mainnet',
            nativeCurrency: {
                name: 'GOLDX',
                symbol: 'GOLDX',
                decimals: 18
            },
            rpcUrls: ['https://mainnet-rpc.goldXchain.io/'],
            blockExplorerUrls: ['https://explorer.goldXchain.io']
            }]
            })
            .then(async () => {
              await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: this.networkID }], // chainId must be in hexadecimal numbers
            // params: [{ chainId: this.networkID }], // chainId must be in hexadecimal numbers
          });
          return false;  
            })
            .catch((error) => {
            console.log("error in adding chain to metamask",error)
            }) 
          }
          
        }
        this.provider.enable();
        let accounts =  await this.web3.eth.getAccounts()
        if (accounts) {
        this.userWallet = accounts[0]
        this.$store.state.userWallet = this.userWallet;

        // this.userWallet = "0xda6a9CA017D493DF28292c7e796555d0EAB75272"
        console.log('MetaMask provider retrived!',accounts[0]);
        return true;  
        }else{
          return false;
        }
        
      } else {
        console.log('Please install MetaMask!');
        return false;
      }
    },
    }
}
</script>

<style scoped>
#navbarx{
  padding-top: 0px;
    width: 100%;
    height: 84px;
    padding-bottom: 0px;
    background: #fff;
    /* background: #eaeaea;  */
  }
@media only screen and (max-width: 552px) {

.cont-layer, .cont-layer-2{width:100% !important;}
.pgoldx{
padding-left:.5% !important;
padding-right:.5% !important;
}

}
@media only screen and (max-width: 1470px) {
  .pgoldx{
padding-left:7%;
}
}
@media only screen and (max-width: 1036px) {
 
.pgoldx{
padding-left:.5% !important;
} 

}
.navx-item{
    cursor:pointer;
  display: inline-block;
  font-family: "Calibri";
    color: #1b1b1b;
  background-color:transparent;
  /* box-shadow: #b99653 1px 1px 10px; */
  transition: 0.5s all;
}
.navx-item.active{
  background-color:transparent;
  color: #b99653;
}
.navx-item:hover{
    font-weight: 800;
    color: #b99653;
}
</style>