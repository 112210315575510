<template>
    <div id="fgadmin">
      <b-modal id="modal-ref" title="Ref. Details"  size="xl">
        <table class="table">
          <thead>
            <tr>
              <th>Buyer Email</th>
              <th>Buy Amount</th>
              <th>Ref Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="det in refDetails" :key="det._id">
              <td>{{det.email}}</td>
              <td>{{det.priceUSD}}</td>
              <td>{{Number(det.priceUSD) * 0.1 }}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td></td>
              <td>{{totalRefUsd}}</td>
            </tr>
          </tbody>
        </table>
      </b-modal>
      <b-navbar type="dark" variant="dark" class="mb-3">
        <b-navbar-brand href="/">
    <img src="../assets/nav-logo.png" width="204"  class="d-none d-md-block align-top" alt="">

        </b-navbar-brand>
        <b-navbar-nav>
          <b-nav-item href="/">Home</b-nav-item>
          <b-nav-item href="/presale">Presale</b-nav-item>
          <!-- <b-nav-item href="#">Settings</b-nav-item> -->
        </b-navbar-nav>
      </b-navbar>
  
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <b-card>
              <b-card-header>Menu</b-card-header>
              <b-list-group horizontal>
                <b-list-group-item  style="cursor: pointer;" @click="action = 'Pending'">Pending</b-list-group-item>
                <b-list-group-item  style="cursor: pointer;" @click="action = 'Verified'">Verified</b-list-group-item>
                <b-list-group-item  style="cursor: pointer;" @click="action = 'Users'">Users</b-list-group-item>
                <b-list-group-item  style="cursor: pointer;" @click="action = 'TXS'">Verified TXs</b-list-group-item>
                <b-list-group-item  style="cursor: pointer;" @click="action = 'Weekly'">Weekly</b-list-group-item>
                <b-list-group-item  style="cursor: pointer;" @click="action = 'WeeklyRefs'">Weekly Refs.</b-list-group-item>
                <b-list-group-item  style="cursor: pointer;" @click="action = 'Rights'">Mining Rts.</b-list-group-item>
                <!-- <b-list-group-item  style="cursor: pointer;">Reports</b-list-group-item> -->
                <!-- <b-list-group-item  style="cursor: pointer;">Tasks</b-list-group-item> -->
              </b-list-group>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <b-card-header>Content</b-card-header>
              <b-card-body>
                <b-col sm="12" v-if="action == 'Pending'">
            <button class="btn mr-1" :class="[(filter === 'All' ? 'btn-primary' : 'btn-outline-primary')]" @click="filter = 'All'">All</button>
            <button class="btn mr-1" :class="[(filter === 'Refiners' ? 'btn-primary' : 'btn-outline-primary')]" @click="filter = 'Refiners'">Refiners</button>
            <button class="btn mr-1" :class="[(filter === 'Miners' ? 'btn-primary' : 'btn-outline-primary')]" @click="filter = 'Miners'">Miners</button>
            <button class="btn mr-1" :class="[(filter === 'Prospectors' ? 'btn-primary' : 'btn-outline-primary')]" @click="filter = 'Prospectors'">Prospectors</button>
            <b-table small :fields="vFields" :items="pendingFiltered" responsive="sm">
                <template #cell(price)="data"> 
               $ {{data.item.priceUSD }} / {{ Number(data.item.priceCrypto).toFixed(2) }} {{ data.item.crypto }}  
                </template>  
                <template #cell(action)="data"> 
               <button class="btn ml-1 btn-sm btn-outline-danger" v-if="data.item.status == 'Pending'" style="font-size: 9px;"  @click="dlt(data.item)">&#10006;</button>
               <button class="btn ml-1 btn-sm btn-success" v-if="data.item.status == 'Pending'" style="font-size: 9px;" @click="approve(data.item)">&#10003;</button>
               <button class="btn ml-1 btn-sm btn-success" v-if="data.item.userWallet == ''" style="font-size: 11px;" @click="addWallet(data.item)">Add Wallet</button>
                </template>
                <template #cell(txHash)="data"> 
                    <div style="margin-top: -15px;">
                        <span class="px-2" style="font-size:33px;" @click="copy(data.item.txHash)">
                            <img src="../assets/copy.png" alt="" style="    width: 23px;
    cursor: pointer;
    margin-top: -11px;" srcset="">
                        </span>
                    <a :href="data.item.txHash" target="_blank" rel="noopener noreferrer"> <img src="../assets/resize (1).png" style="max-width:25px;margin-top:-12px;" alt="" srcset=""> </a>
                
                    </div>
                </template>
                <template #cell(txHashComplete)="data"> 
                    <div style="margin-top: -15px;">
                        <span class="px-2" style="font-size:33px;" @click="copy(data.item.txHashComplete)">
                            <img src="../assets/copy.png" alt="" style="    width: 23px;
    cursor: pointer;
    margin-top: -11px;" srcset="">
                        </span>
                    <a :href="data.item.txHashComplete" target="_blank" rel="noopener noreferrer"> <img src="../assets/resize (1).png" style="max-width:25px;margin-top:-12px;" alt="" srcset=""> </a>
                
                    </div>
                </template>  
                <template #cell(purchaser)="data"> 
                <div>
                    {{ data.item.email }}
                </div>
                </template> 
                <template #cell(refWallet)="data"> 
                <div>
                    {{ data.item.refWallet.slice(0, 6) }}......{{ data.item.refWallet.slice(-6) }}
                    <img src="../assets/copy.png" alt="" style="    width: 23px;
    cursor: pointer;
    margin-top: -11px;" srcset="" @click="copyw(data.item.refWallet)">
                </div>
                </template> 
                
                <template #cell(createDate)="data"> 
                <div>
                    {{ getShortDate(new Date(data.item.createDate)) }}
                </div>
                </template> 
                
            </b-table>
          </b-col>
          <b-col sm="12" v-else-if="action == 'Verified'">
            
            <button class="btn mr-1" :class="[(filter === 'All' ? 'btn-primary' : 'btn-outline-primary')]" @click="filter = 'All'">All</button>
            <button class="btn mr-1" :class="[(filter === 'Refiners' ? 'btn-primary' : 'btn-outline-primary')]" @click="filter = 'Refiners'">Refiners</button>
            <button class="btn mr-1" :class="[(filter === 'Miners' ? 'btn-primary' : 'btn-outline-primary')]" @click="filter = 'Miners'">Miners</button>
            <button class="btn mr-1" :class="[(filter === 'Prospectors' ? 'btn-primary' : 'btn-outline-primary')]" @click="filter = 'Prospectors'">Prospectors</button>
            <b-table small :fields="vFields" :items="verifiedFiltered" responsive="sm">
                <template #cell(price)="data"> 
               $ {{data.item.priceUSD }} / {{ Number(data.item.priceCrypto).toFixed(2) }} {{ data.item.crypto }}  
                </template>  
                <template #cell(action)="data"> 
               <button class="btn btn-sm btn-danger" v-if="data.item.status == 'Pending'" style="font-size: 9px;"  @click="dlt(data.item)">&#10006;</button>
               <button class="btn btn-sm btn-success" v-if="data.item.status == 'Pending'" style="font-size: 9px;" @click="approve(data.item)">&#10003;</button>
               <button class="btn ml-1 btn-sm btn-success" v-if="data.item.userWallet == ''" style="font-size: 11px;" @click="addWallet(data.item)">Add Wallet</button>
                </template>
                <template #cell(txHash)="data"> 
                <div style="margin-top: -15px;">
                    <span class="px-2" style="font-size:33px;" @click="copy(data.item.txHash)">&#x274F;</span>
                    <a :href="data.item.txHash" target="_blank" rel="noopener noreferrer"> <img src="../assets/resize (1).png" style="max-width:25px;margin-top:-12px;" alt="" srcset=""> </a>

                </div>
                </template>
                <template #cell(fortune)="data"> 
                
                <div v-if="nftUsersEmails[data.item.email] !== undefined">
                        <span class="text-success" v-if="nftUsersEmails[data.item.email].length"> <b class="pr-2">&#x2713;</b> {{nftUsersEmails[data.item.email]}} </span>
                        <span class="text-success" v-else> N/A  </span>
                </div>
                </template> 
                <template #cell(purchaser)="data"> 
                <div>
                    {{ data.item.email }}
                </div>
                </template>

                <template #cell(createDate)="data"> 
                <div>
                    {{ getShortDate(new Date(data.item.createDate)) }}
                </div>
                </template> 


                <template #cell(refWallet)="data"> 
                <div>
                    {{ data.item.refWallet.slice(0, 6) }}......{{ data.item.refWallet.slice(-6) }}
                    <img src="../assets/copy.png" alt="" style="    width: 23px;
    cursor: pointer;
    margin-top: -11px;" srcset="" @click="copyw(data.item.refWallet)">
                </div>
                </template> 
            </b-table>
          </b-col>
          <b-col sm="12" v-else-if="action == 'TXS'">
            <b-input-group class="mt-3">
    <b-form-input placeholder="Transaction Hash" v-model='search'></b-form-input>
    <b-input-group-append>
      <b-button variant="outline-danger" @click="searchValue = ''; search = '';" v-if="searchValue.length">Clear</b-button>
      <b-button variant="outline-success" @click="searchValue = search">Search</b-button>
    </b-input-group-append>
  </b-input-group>
            <b-table small :fields="tFields" :items="verifiedFilteredSearched" responsive="sm">
                <template #cell(price)="data">
                  <div style="max-width:150px;">
                    $ {{data.item.priceUSD }} / {{ Number(data.item.priceCrypto).toFixed(2) }} {{ data.item.crypto }}  
                  </div> 
                </template>  
                <template #cell(txHash)="data"> 
                <div style="margin-top: -15px;">
                    <span class="px-2" style="font-size:33px;" @click="copy(data.item.txHash)">&#x274F;</span>
                    <a :href="data.item.txHash" target="_blank" rel="noopener noreferrer"> <img src="../assets/resize (1).png" style="max-width:25px;margin-top:-12px;" alt="" srcset=""> </a>

                </div>
                </template> 
                <template #cell(purchaser)="data"> 
                <div style="max-width:200px;">
                    {{ data.item.email }}
                </div>
                </template> 
                <template #cell(refWallet)="data"> 
                <div>
                    {{ data.item.txHash.slice(-64) }}
                </div>
                </template> 
                <template #cell(wallet)="data">
                  <div v-if="data.item.wallet">
                    {{ data.item.wallet.slice(0, 6) }}......{{ data.item.wallet.slice(-6) }}
                    <img src="../assets/copy.png" alt="" style="    width: 23px;
    cursor: pointer;
    margin-top: -11px;" srcset="" @click="copyws(data.item.wallet,'Wallet')">
                  </div>
                  
                  
                </template>
                <!-- <template #cell(createDate)="data"> 
                <div>
                    {{ getShortDate(data.item.createDate) }}
                </div>
                </template>  -->
            </b-table>
          </b-col>
          <b-col sm="12" v-else-if="action == 'Weekly'">
            
  <div v-for="item in bWeekly" :key="'week'+item.week" class="d-inline-block mr-2">
  <b-button
  
      :aria-expanded="active == item.week ? 'true' : 'false'"
      :aria-controls="'week'+item.week"
      @click="active = 'week'+item.week"
  variant="primary">{{ item.week }} 
  (From: {{ getShortDate(new Date(item.startDate))  }} TO {{ getShortDate(new Date(item.endDate))  }} )</b-button>

</div>



<div v-for="item in bWeekly" :key="item.week">

  <b-collapse :id="'week'+item.week" class="mt-2" :class="active !== ('week'+item.week) ? null : 'collapsed clp'">
    <b-card v-if="item.data.length">
        <button class="btn btn-primary" @click="genCSV(item.data)">Generate CSV</button>
        <b-list-group>
            <b-list-group-item v-for="dd in item.data" :key="dd.wallet">{{ dd.wallet }} <span class="float-right">{{ dd.totalUnits }}</span> </b-list-group-item>
            </b-list-group>
    </b-card>
  </b-collapse>
</div>
          </b-col>
          <b-col sm="12" v-else-if="action == 'WeeklyRefs'">
            <div class="px-4">
              <ul class="list">
                <li><p>Total <span class="float-right"> {{RTotalTotal}} </span></p></li>
                <li v-for="tt in RTotalSorted" :key="tt.email">
                <p>{{tt.email}} <span class="float-right"> {{tt.totalUnits}}</span></p>
                </li>
              </ul>
            </div>
            
            <!-- <div v-for="item in rWeekly" :key="'week'+item.week" class="d-inline-block mr-2">
            <b-button
            
                :aria-expanded="active == item.week ? 'true' : 'false'"
                :aria-controls="'week'+item.week"
                @click="active = 'week'+item.week"
            variant="primary">{{ item.week }} 
            (From: {{ getShortDate(new Date(item.startDate))  }} TO {{ getShortDate(new Date(item.endDate))  }} )</b-button>
          
          </div> -->
          
          
          
          <!-- <div v-for="item in rWeekly" :key="item.week">
          
            <b-collapse :id="'week'+item.week" class="mt-2" :class="active !== ('week'+item.week) ? null : 'collapsed clp'">
              <b-card v-if="item.data.length">
                  <button class="btn btn-primary" @click="genCSVRefs(item.data)">Generate CSV</button>
                  <b-list-group>
                      <b-list-group-item v-for="dd in item.data" :key="dd.wallet">{{ dd.email }} <span class="float-right">{{ dd.totalUnits }}</span> </b-list-group-item>
                      </b-list-group>
              </b-card>
            </b-collapse>
          </div> -->
                    </b-col>
                    <b-col sm="12" v-else-if="action == 'Rights'">
                      <rights />
                      </b-col>          
          <b-col sm="12" v-else>
            
            <!-- <button class="btn mr-1" :class="[(filter === 'All' ? 'btn-primary' : 'btn-outline-primary')]" @click="filter = 'All'">All</button> -->
            <!-- <button class="btn mr-1" :class="[(filter === 'Refiners' ? 'btn-primary' : 'btn-outline-primary')]" @click="filter = 'Refiners'">Refiners</button> -->
            <!-- <button class="btn mr-1" :class="[(filter === 'Miners' ? 'btn-primary' : 'btn-outline-primary')]" @click="filter = 'Miners'">Miners</button> -->
            <!-- <button class="btn mr-1" :class="[(filter === 'Prospectors' ? 'btn-primary' : 'btn-outline-primary')]" @click="filter = 'Prospectors'">Prospectors</button> -->
            <p class="px-5 mb-0"> <b>Total owed: <span class="float-right">{{(TOTALOWED - USDSENTTOTAL)}}</span> </b> </p>
            <b-table small :fields="uFields" :items="users" responsive="sm">
                <template #cell(wallet)="data"> 
                <div>
                    {{ data.item.wallet.slice(0, 6) }}......{{ data.item.wallet.slice(-6) }}
                    <img src="../assets/copy.png" alt="" style="    width: 23px;
    cursor: pointer;
    margin-top: -11px;" srcset="" @click="copyws(data.item.wallet,'Wallet')">
                </div>
                </template> 
                <template #cell(email)="data"> 
                <div>
                    {{ data.item.email.slice(0, 6) }}......{{ data.item.email.slice(-6) }}
                    <img src="../assets/copy.png" alt="" style="    width: 23px;
    cursor: pointer;
    margin-top: -11px;" srcset="" @click="copyws(data.item.email,'Email')">
                </div>
                </template> 
                <template #cell(usdsent)="data"> 
                <span v-if="USDSENT[data.item.wallet] == undefined">0</span>
                <span v-else>{{USDSENT[data.item.wallet]}}</span>
                </template>
                <template #cell(refDetails)="data"> 
                <button class="btn btn-sm btn-success" @click="loadRefDetails(data.item.wallet)">Details</button>
                </template> 
                
            </b-table>
          </b-col>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
   
   <script>
   import Rights from './Rights.vue'
   var WAValidator = require('wallet-address-validator');
  import { mapState } from 'vuex'
     export default {
       name: 'AdminDashboard',
       components:{Rights},
       computed:{
      ...mapState(['dist','nftUsers','nftUsersEmails','RTotal']),
      totalRefUsd(){
        if(this.refDetails !== null){
          let amount = 0;
          this.refDetails.forEach(element => {
            amount += Number(element.priceUSD)
          });
          return  (amount * 0.1).toFixed(1) 
        }else{
          return 0
        }
      },
      USDSENT(){
        return {"0x441097ef3fd5b7eb90421f438d6a91629707aa83":491,
"0xB75bF4A9b0Ff9dd574C7dC6D0a7614B2a6d81A69":11.5,
"0x8e523F4D8e197C19e68F62095F280309DdB3D9C6":  16.5,
"0xc199c02cEF4b7069e6Bb3438Fed2595a0b799EF3":  18,
"0x38032b73A5BDe0C6cf94c7BeaFD202F9235Bd145":  29.5,
"0x6C48D8b06AD3216D2904409711526f33F28Ed4a1":13,
"0x39a3e10Adc37E54bba54bDF3F5efD5d0E76995c3":2.5,
"0x427c7A68179A1576E39baEf35432fC9f6d7F6c18":2,
"0xd661E89dAa2Ecf9dFE6a649618c91461fF940fc3":1.5,
"0xD96129b41d0c6938fC2D1C7019f5441Fe4f502F5":1,
"0xBa55e9FD7BD859cCfc44bB05D2E1B4620398b7fB":1,
"0xBf982cD4FAd559f4A097e1B1bbBdF130d822139b":1,
"0x508D6198037b6903B1Fe8CEC8c5f972838Eaf741":1,
"0xB4a8b2Cf5726bE9cd1C7964fcfC74D57D5256F42":1,
"0xFa87b0886d960476858d9393695e760cb2821D11":1,
"0x2D0C7e371b3D0Fb0bb41Aaef9911378DcA88d50B":0.5,
"0xaDF40FCff153f93284BAF5AFBC6f304465131273":0.5,
"0x9D084891906912Ea19263A8069855D27368c8408":0.5,
"0x162F89b19ada67433e00F02788Ac5EC9e39412D9":0.5,
"0x9720DFe759A42aC8c4fd4F3a60b767e1eEA387E8":0.5,
"0x5c8f52778c1aF852748B10DcB683aDF7a79aB273":0.5,
"0x9f8A455F8f3077d30876F48Fc84164a2012fD8bc":0.5,
"0x123A2e008636977508098263c588b983FbF15FBf":0.5,
"0x560068787A16587415C0fd763c8a5df624B8A555":423,
"0x441097EF3fd5B7EB90421F438D6A91629707AA83":491,}
      },
      USDSENTTOTAL(){
        let total = 0;
        for (const key in this.USDSENT) {
          total += this.USDSENT[key]
        }
        return total;
      },
      users(){
          let users = [];
            this.$store.state.usersAll.forEach(element => {
              if(element.usd  > 0) users.push(element);
            });
            //sort by usd
            let sorted = users.sort((a, b) => b.usd - a.usd);
            return sorted
        },
      TOTALOWED(){
        let owed = 0;
        this.users.forEach(element => {
          owed += element.usd
        });
        return owed;
      },  
      RTotalSorted(){
        return this.RTotal.slice().sort((a, b) => b.totalUnits - a.totalUnits);
      },
      RTotalTotal(){
        let tt = 0;
        this.RTotal.forEach(element => {
          tt+= element.totalUnits
        });
        return tt;
      },
        ps(){
          return this.$store.state.ps
        },
        totalSold(){
          let qts = {
            Prospectors: 0,
            Miners:0,
            Refiners:0
          }
          this.$store.state.verified.forEach(element => {
            qts[element.type] += Number(element.qty)
          });
          return qts;
        },
        rWeekly(){
            
            
            return this.$store.state.RWeekly
         },
         allSales(){
          let amount = 0;
          this.$store.state.verified.forEach(element => {
            amount += Number(element.priceUSD)
            });
return amount
         },
        bWeekly(){
            
            
           return this.$store.state.BWeekly
        },
        
        verifiedFilteredSearched(){
            if(this.searchValue.length)
            {
let pds = [];
this.$store.state.verified.forEach(element => {
    if(element.txHash.includes(this.searchValue))
    {
        pds.push(element)
    }
});
// pds.sort((a, b) => { a.createDate - b.createDate })
pds.sort((a, b) => { new Date(a.createDate) - new Date(b.createDate) })
pds = pds.reverse()
return pds;
            }else{
                return this.$store.state.verified
            }
        },
        
           username(){
               return this.$route.params.username
           },

           pendingFiltered(){
               let items = []
               if(this.filter == "All")
               {
                  items =  this.$store.state.pending
               }else if(this.filter == "Prospectors")
               {
                   // let items = [];
                   this.$store.state.pending.forEach(element => {
                       if(element.type == "Prospectors"){
                           items.push(element)
                       }
                   });
               }
               else if(this.filter == "Miners")
               {
                   // let items = [];
                   this.$store.state.pending.forEach(element => {
                       if(element.type == "Miners"){
                           items.push(element)
                       }
                   });
               }
               else if(this.filter == "Refiners")
               {
                   // let items = [];
                   this.$store.state.pending.forEach(element => {
                       if(element.type == "Refiners"){
                           items.push(element)
                       }
                   });
               }
               items.sort((a, b) => { new Date(a.createDate) - new Date(b.createDate) })

               return items
           },
           verifiedFiltered(){
               let items = []
               if(this.filter == "All")
               {
                  items =  this.$store.state.verified
               }else if(this.filter == "Prospectors")
               {
                   // let items = [];
                   this.$store.state.verified.forEach(element => {
                       if(element.type == "Prospectors"){
                           items.push(element)
                       }
                   });
               }
               else if(this.filter == "Miners")
               {
                   // let items = [];
                   this.$store.state.verified.forEach(element => {
                       if(element.type == "Miners"){
                           items.push(element)
                       }
                   });
               }
               else if(this.filter == "Refiners")
               {
                   // let items = [];
                   this.$store.state.verified.forEach(element => {
                       if(element.type == "Refiners"){
                           items.push(element)
                       }
                   });
               }
               items.sort((a, b) => { new Date(b.createDate) - new Date(a.createDate) })
               items = items.reverse()
               return items
           },
           pending(){
               return this.$store.state.pending
           },
           verified(){
               return this.$store.state.verified
           },
           groupedRecords() {
      return this.groupByDateDifference(this.$store.state.usersAll, this.startDate);
    },
    groupedRecordsWallets() {
      let weeks = {};
      this.groupedRecords.forEach((element, index) => {
        weeks[('week'+index)] = {};
        weeks[('week'+index)].startDate = element[0].createDate
        weeks[('week'+index)].endDate = element[ ( element.length - 1 ) ].createDate
        weeks[('week'+index)].wallets = {}
        element.forEach(elm => {
          if(elm.nfts){
            if(weeks[('week'+index)].wallets[elm.wallet] !== undefined )
          {
            weeks[('week'+index)].wallets[elm.wallet] += elm.nfts  
          }else{
            weeks[('week'+index)].wallets[elm.wallet] = elm.nfts  
          }
          }
        });
        
        
      });
      for (const week in weeks) {
        const keyValueArray = Object.entries(weeks[week].wallets);

        // Sort the array based on the values (assuming values are comparable)
        keyValueArray.sort((a, b) => b[1] - a[1] ); // Change the comparison logic for other data types

        // Create a new object from the sorted array
        const sortedObject = {};
        for (const [key, value] of keyValueArray) {
        sortedObject[key] = value;
        }
        weeks[week].wallets = sortedObject
        }

      return weeks;
    }
       },
       methods:{
        async loadRefDetails(ref){
          let details = await this.$store.dispatch("getRefDetails",{ref})
          this.refDetails = details
        this.$bvModal.show("modal-ref")

        },
        genCSVRefs(data){
        let dda = [];
        data.forEach(element => {
                if(element.email)
                {
                    dda.push({ wallet: element.email, value:element.totalUnits });
                }
        });

      const csvContent = this.convertDataToCSV(dda);
      this.downloadCSV(csvContent, 'custom_data.csv');
        },
        genCSV(data){
        let dda = [];
        data.forEach(element => {
            for (let i = 0; i < element.totalUnits; i++) {
                if(element.wallet)
                {
                    dda.push({wallet: element.wallet});
                }
        }
        });

      const csvContent = this.convertDataToCSV(dda);
      this.downloadCSV(csvContent, 'custom_data.csv');
        },
        convertDataToCSV(data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);

      // Add headers to the CSV content
      csvRows.push(headers.join(','));

      // Add data rows to the CSV content
      for (const item of data) {
        const values = headers.map(header => item[header]);
        csvRows.push(values.join(','));
      }

      return csvRows.join('\n');
    },
    downloadCSV(csvContent, fileName) {
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();

      // Clean up the object URL after download
      URL.revokeObjectURL(url);
    },
        getShortDateAdd(date, days, obj, key) {
            console.log(date, days, obj, key)
            const keys = Object.keys(obj);
            let index =  keys.indexOf(key);
            console.log(index)
            date.setDate(date.getDate() + days);
            const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad with leading 0 if needed
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (January is 0) and pad with leading 0 if needed

  return `${day}/${month}`;
},
        getShortDate(date) {
  const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad with leading 0 if needed
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (January is 0) and pad with leading 0 if needed

  return `${day}/${month}`;
},
        groupByDateDifference(records, startDate) {
            console.log(records, startDate)
      const groups = [];
    //   let currentGroup = [];
// return groups

    //   for (const record of records) {
    //     const currentDate = new Date(record.createDate);
    //     const daysDiff = this.getDaysDifference(startDate, currentDate);

    //     if (daysDiff >= 0 && daysDiff < 7) {
    //       currentGroup.push(record);
    //     } else if (currentGroup.length > 0) {
    //       groups.push(currentGroup);
    //       currentGroup = [record];
    //       startDate = this.getNextSunday(currentDate);
    //     } else {
    //       startDate = this.getNextSunday(currentDate);
    //     }
    //   }
    //   if (currentGroup.length > 0) {
    //     groups.push(currentGroup);
    //   }

      return groups;
    },
    getDaysDifference(date1, date2) {
      const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
      return Math.round(Math.abs(date2 - date1) / oneDay);
    },
    getNextSunday(date) {
      const dayOfWeek = date.getDay();
      const daysUntilNextSunday = 7 - dayOfWeek;
      const nextSunday = new Date(date.getTime() + daysUntilNextSunday * 24 * 60 * 60 * 1000);
      nextSunday.setHours(0, 0, 0, 0);
      return nextSunday;
    },
        copyw(link)
  {
    navigator.clipboard.writeText(link)
      this.$root.Toast.fire({icon: "success",title:"Ref. Wallet Address Copied"})
  },
  copyws(link, title)
  {
    navigator.clipboard.writeText(link)
      this.$root.Toast.fire({icon: "success",title:title+" Address Copied"})
  },
           async auth(){
               let dec = await this.$store.dispatch("auth", {token: this.token, username: this.username})
               console.log(dec)
               if(dec){
                   this.authorized = true
               }
           },
           async checkAuth(token){
               let dec = await this.$store.dispatch("auth", {token, username: this.username})
               console.log(dec)
               if(dec){
                   this.authorized = true
               }
           },
           async addWallet(item){
               console.log(item)
               this.$root.Swal.fire({
               title: 'Submit User wallet',
               input: 'text',
               inputAttributes: {
                   autocapitalize: 'off'
               },
               showCancelButton: true,
               confirmButtonText: 'Add Wallet',
               showLoaderOnConfirm: true,
               preConfirm: async(login) => {
           let wa = WAValidator.validate(login,'ethereum');
           if(wa){
               let dec = await this.$store.dispatch("addWallet", {wallet: login, item: item._id})
               console.log(dec)
               this.$root.Toast.fire({icon: "success",title:"Wallet has been added"})
               return true
           }else{
               this.$root.Toast.fire({icon: "warning",title:"Invalid Wallet, action aborted"})
               return false
           }
                   
               },
               allowOutsideClick: () => !this.$root.Swal.isLoading()
               }).then((result) => {
               if (result.isConfirmed) {
                   console.log("result is confirmed", result)
               }
               })
           },
           async approve(item){
               let dec = await this.$store.dispatch("approvePurchase",{item})
               console.log(dec)
               if(dec.status){
                   this.$root.Toast.fire({icon: "success",title:"Purchase Entry Has Been Approved"})
               }else{
                   this.$root.Toast.fire({icon: "success",title:"Failed to approve the purchase Entry"})
               }
           },
           async dlt(item){
               let dec = await this.$store.dispatch("deletePurchase",{item})
               console.log(dec)
               if(dec.status){
                   this.$root.Toast.fire({icon: "success",title:"Purchase Entry Has Been Delete"})
               }else{
                   this.$root.Toast.fire({icon: "success",title:"Failed to delete the purchase Entry"})
               }
           },
           copy(tx){
         navigator.clipboard.writeText(tx)
               // console.log(tx)
         this.$root.Toast.fire({icon: "success",title:"Explorer link Copied."})
   
           },
       },
       async mounted(){
           //check auth
    await this.$store.dispatch("setToken")

           this.$store.dispatch("getFORTUNEdata")
           this.$store.dispatch("pullPurchases")
           this.$store.dispatch("pullUsers")
           this.$store.dispatch("pullWeekly")
           this.$store.dispatch("pullWeeklyRefs")
           this.$store.dispatch("pullTotalRefs")
           let token = localStorage.getItem('frtgtoken')
           if(token){
               this.checkAuth(token)
           }else{
               this.authorized = false
           }
       },
       data(){
           return {
            refDetails:null,
               authorized: null,
               token:"",
               active:"",
      startDate: new Date('2023-07-05'),
               search:'',
               searchValue:'',
               searchValueWallet:'',
               searchWallet:'',
               filter:"All",
               vFields:[
           {key: 'type',label: 'NFT Class',sortable: false},
           {key: 'purchaser',label: 'Purchaser',sortable: false},
           {key: 'createDate',label: 'Date',sortable: false},
           {key: 'qty',label: 'Quantity',sortable: false},
           {key: 'fortune',label: 'Fortune',sortable: false},
           {key: 'price',label: 'Price/Value',sortable: false},
           {key: 'txHash',label: 'Tx. Token Send',sortable: false},
           {key: 'txHashComplete',label: 'Tx. Complete ',sortable: false},
          //  {key: 'txHash',label: 'Tx. Link',sortable: false},
           {key: 'refWallet',label: 'Ref. Wallet',sortable: false},
           {key: 'action',label: 'Actions',sortable: false},
               ],
               tFields:[
           {key: 'type',label: 'NFT Class',sortable: false},
           {key: 'purchaser',label: 'Purchaser',sortable: false},
        //    {key: 'qty',label: 'Quantity',sortable: false},
           {key: 'price',label: 'Price/Value',sortable: false},
           {key: 'txHash',label: 'Tx. Link',sortable: false},
           {key: 'wallet',label: 'Wallet',sortable: false},
          //  {key: 'isSelfmint',label: 'Self Mint',sortable: false},
           {key: 'refWallet',label: 'txHash',sortable: false},
           {key: 'sacrifice',label: 'Sacrifice',sortable: false},
           {key: 'needSingle',label: 'Need Single',sortable: false},
        //    {key: 'action',label: 'Actions',sortable: false},
               ],   
               uFields:[
           {key: 'email',label: 'Email',sortable: false},
           {key: 'wallet',label: 'wallet',sortable: false},
           {key: 'username',label: 'Username',sortable: false},
           {key: 'validRefs',label: 'Fuseg Ref.',sortable: false},
           {key: 'rewards',label: 'Ref. Rewards',sortable: false},
           {key: 'buyRewards',label: 'Buy Rewards',sortable: false},
           {key: 'usd',label: 'USD Earned',sortable: false},
           {key: 'usdsent',label: 'USD Sent',sortable: false},
           {key: 'nfts',label: 'Ref. NFT Units',sortable: false},
           {key: 'nft',label: 'Pur. Nfts',sortable: false},
           {key: 'nftsCounter',label: 'Ref. NFTs',sortable: false},
           {key: 'purchasedNfts',label: 'Pur. NFT Units',sortable: false},
           {key: 'refDetails',label: 'Ref Details',sortable: false},
        //    {key: 'action',label: 'Actions',sortable: false},
               ],
               // pFields:[],
   action:"Pending"
           }
       },
       // Add your component logic here
     }
     </script>
    
  <style scoped>
  /* Dark theme styles */
  body {
    background-color: #1e1e1e;
    color: #fff;
  }
  
  .b-navbar-dark,
  .b-navbar-dark .navbar-nav .nav-link {
    color: #fff;
  }
  
  .b-card-header {
    background-color: #343a40;
    color: #fff;
  }
  
  .b-list-group-item,
  .b-list-group-item.active {
    background-color: #343a40;
    color: #fff;
  }
  
  .b-card {
    background-color: #212529;
    color: #fff;
  }

  .clp{
    display: block !important;
  }
  #fgadmin .row, 
  #fgadmin .container-fluid, 
  #fgadmin .col-sm-12, 
  #fgadmin .col-12, 
  #fgadmin .col{
    margin: 1px !important;
    padding: 1px !important;
  }
  </style>
  