<template>
  
    <div class='homef' style="padding-left: 5px;position:relative;bottom:0px;width: 100%;">
        <!-- <br> -->
        <div class="row m-0 p-0">
          <div class="col-12 m-0 p-0">
        <div class="row justify-content-between m-0 px-0 px-md-4">
          <div class="col-lg-6 col-6 px-0">
            <span class='text-white float-left d-none d-md-block'> 
              <a href="mailto: info@fuse.gold?subject=Contact Fuse.Gold" 
            style="font-size: 1vw;line-height: 2vw; color: rgb(205, 205, 205);"> info@fuse.gold</a> 
              </span>
            
            
            <span class="float-left d-none d-md-block" 
            style="color: rgb(128, 128, 128);font-size: 1vw;padding-left: 21px;line-height: 2vw;">
                fuse.gold &#169; 2022 All Rights Reserved
            </span>




              <span class='text-white d-md-none'> 
                <a href="mailto: info@fuse.gold?subject=Contact Fuse.Gold" 
                style="color: rgb(205, 205, 205);" class="cccs"> info@fuse.gold </a>  </span>

            <span class="d-md-none cccs" style="color: rgb(128, 128, 128);padding-left: 5px;">
                fuse.gold &#169; 2022 All Rights Reserved
              </span>

          </div>
          <div class="col-lg-6 col-6 px-0">
            <div class="d-none d-md-block">
              <div class="eq-width"> 
                    <a href="https://t.me/fusegold" title="Telegram" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Telegram.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="eq-width"> 
                    <a href="https://twitter.com/fusegold" title="Twitter" target="_blank" >
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Twitter.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="eq-width"> 
                    <a href="https://www.youtube.com/channel/UCGVTgHZtRMYYNzG5bIy7iQQ/featured" title="You Tube" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/YT.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="eq-width"> 
                    <a href="https://discord.gg/X5UtZsqBhC" title="Discord" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Discord.png" alt="" srcset="" />
                    </a>
                  </div>
  
                  <div class="eq-width"> 
                    <a href="" title="Github" target="_blank">
                      <img class="sc-link d-block mx-auto diss" height="auto" width="95%" src="@/assets/footer/Github.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="eq-width"> 
                    <a href="https://www.facebook.com/fuse.gold/" title="Facebook" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Facebook.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="eq-width"> 
                    <a href="" title="Medium" target="_blank">
                      <img class="sc-link d-block mx-auto diss" height="auto" width="95%" src="@/assets/footer/Medium.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="eq-width"> 
                    <a href="https://www.linkedin.com/company/fuse-gold-ltd" title="Linkedin" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Linkedin.png" alt="" srcset="" />
                    </a>
                  </div>
            </div>



            <div class="d-md-none">
              <div class="eq-width-m"> 
                    <a href="https://t.me/fusegold" title="Telegram" target="_blank">
                      <img class="sc-link-m d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Telegram.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="eq-width-m"> 
                    <a href="https://twitter.com/fusegold" title="Twitter" target="_blank" >
                      <img class="sc-link-m d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Twitter.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="eq-width-m"> 
                    <a href="https://www.youtube.com/channel/UCGVTgHZtRMYYNzG5bIy7iQQ/featured" title="You Tube" target="_blank">
                      <img class="sc-link-m d-block mx-auto" height="auto" width="95%" src="@/assets/footer/YT.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="eq-width-m"> 
                    <a href="https://discord.gg/X5UtZsqBhC" title="Discord" target="_blank">
                      <img class="sc-link-m d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Discord.png" alt="" srcset="" />
                    </a>
                  </div>
  
                  <div class="eq-width-m"> 
                    <a href="" title="Github" target="_blank">
                      <img class="sc-link-m d-block mx-auto diss" height="auto" width="95%" src="@/assets/footer/Github.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="eq-width-m"> 
                    <a href="https://www.facebook.com/fuse.gold/" title="Facebook" target="_blank">
                      <img class="sc-link-m d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Facebook.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="eq-width-m"> 
                    <a href="" title="Medium" target="_blank">
                      <img class="sc-link-m d-block mx-auto diss" height="auto" width="95%" src="@/assets/footer/Medium.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="eq-width-m"> 
                    <a href="https://www.linkedin.com/company/fuse-gold-ltd" title="Linkedin" target="_blank">
                      <img class="sc-link-m d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Linkedin.png" alt="" srcset="" />
                    </a>
                  </div>
            </div>
          </div>


            
        </div>
        </div>
    </div>    
    </div>    
  </template>
  
  <script>
  // import VuePdfApp from "vue-pdf-app";
  // import this to use default icons for buttons
  // import "vue-pdf-app/dist/icons/main.css";
//   import {mapState} from "vuex";
  export default {
      components: {
      // VuePdfApp
    },
    computed:{
    //   ...mapState(['dark'])
    },
    mounted() {
      // this.dark = this.$store.state.dark
    },
    data(){
      return {
              ht:0,
              dark: true
      }
    },
      methods:{
        goToWp(){
          window.open('https://drive.google.com/file/d/1S3um-2jn5G6UVPSXeEWfFjx2LDuig9lR/view?usp=sharing','WhitePaper').focus()
        },
         increaseWidth() {
              console.log('increaseing width in 2 seconds')
              setTimeout(() => {
              this.ht = 85;
                  
              }, 2000);
          },
        handle(value){
          this.dark = value;
          this.dark = value
          localStorage.setItem('guseGoldDarkMode', value);
          window.scrollTo(0,0);
        },
          goTo(path){ this.$router.push({name:path})},
      },
      
  }
  </script>
  
  <style scoped>
  .sc-link{
    padding: 7px;
    max-width: 84px;
  }
  .sc-link{
    padding: 0px;
    max-width: 84px;
  }
  .eq-width{
    float: right;
    width: 8%;
    /* margin-top: -19px; */
  }
  .eq-width-m{
    float: right;
    width: 10%;
    /* margin-top:0px; */
    /* margin-top: -19px; */
  }
  .homef{

  }
  .cccs{
    font-size: 5px;
    line-height: 5px;
    margin-top: 5px;
    display: inline-block;
    float: left;
  }

</style>